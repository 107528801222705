import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { baseApi } from '@libs/axios';
import { queryClient } from '@libs/react-query';

import {
  Filters,
  FiltersInvoiceList,
  InvoiceByDayResponse,
  InvoiceByDetailsResponse,
  InvoiceByServiceResponse,
  InvoiceByOrgUnitResponse,
  InvoiceListResponse,
} from '@services/invoice/types';

import parseData from '@utils/parseData';

import { ApiError, ApiResponse } from '@entities/Response';

import {
  downloadInvoice,
  listInvoiceByDay,
  listInvoiceByDetails,
  listInvoiceByOrgUnit,
  listInvoiceByService,
} from './keys';

// Requests

const getInvoiceByOrgUnit = async (
  filters?: Filters
): Promise<InvoiceByOrgUnitResponse> => {
  return await baseApi
    .post<ApiResponse<InvoiceByOrgUnitResponse>>(`/invoices/orgUnit`, filters)
    .then(parseData);
};

const getInvoiceByService = async (
  filters?: Filters
): Promise<InvoiceByServiceResponse> => {
  return await baseApi
    .post<ApiResponse<InvoiceByServiceResponse>>(`/invoices/service`, filters)
    .then(parseData);
};

const getInvoiceByDay = async (
  filters?: Filters
): Promise<InvoiceByDayResponse> => {
  return await baseApi
    .post<ApiResponse<InvoiceByDayResponse>>(`/invoices/day`, filters)
    .then(parseData);
};

const getInvoiceByDetails = async (
  filters?: Filters
): Promise<InvoiceByDetailsResponse> => {
  return await baseApi
    .post<ApiResponse<InvoiceByDetailsResponse>>(`/invoices/detail`, filters)
    .then(parseData);
};

const getInvoiceList = async (
  filters?: FiltersInvoiceList
): Promise<InvoiceListResponse> => {
  return await baseApi
    .post<ApiResponse<InvoiceListResponse>>(`/invoices/list`, filters)
    .then(parseData);
};

// Query

export const useInvoiceByOrgUnit = () => {
  const queryClient = useQueryClient();

  return useMutation<InvoiceByOrgUnitResponse, ApiError, Filters>({
    mutationKey: listInvoiceByOrgUnit(),
    mutationFn: (filters: Filters) => getInvoiceByOrgUnit(filters),
    onSuccess: (data) => {
      // Armazena os dados no cache sob a chave `listInvoiceByOrgUnit`
      queryClient.setQueryData(listInvoiceByOrgUnit(), data);
    },
    
  });
};

export const useInvoiceByService = () => {
  const queryClient = useQueryClient();

  return useMutation<InvoiceByServiceResponse, ApiError, Filters>({
    mutationKey: listInvoiceByService(),
    mutationFn: (filters: Filters) => getInvoiceByService(filters),
    onSuccess: (data) => {
      // Armazena os dados no cache sob a chave `listInvoiceByOrgUnit`
      queryClient.setQueryData(listInvoiceByService(), data);
    },
  });
};

export const useInvoiceByDay = () => {
  const queryClient = useQueryClient();

  return useMutation<InvoiceByDayResponse, ApiError, Filters>({
    mutationKey: listInvoiceByDay(),
    mutationFn: (filters: Filters) => getInvoiceByDay(filters),
    onSuccess: (data) => {
      // Armazena os dados no cache sob a chave `listInvoiceByOrgUnit`
      queryClient.setQueryData(listInvoiceByDay(), data);
    },
  });
};

export const useInvoiceByDetails = () => {
  const queryClient = useQueryClient();

  return useMutation<InvoiceByDetailsResponse, ApiError, Filters>({
    mutationKey: listInvoiceByDetails(),
    mutationFn: (filters: Filters) => getInvoiceByDetails(filters),
    onSuccess: (data) => {
      // Armazena os dados no cache sob a chave `listInvoiceByOrgUnit`
      queryClient.setQueryData(listInvoiceByDetails(), data);
    },
  });
};

export const useFetchInvoiceList = () => {
  return useMutation<InvoiceListResponse, ApiError, FiltersInvoiceList>({
    mutationFn: (filters: FiltersInvoiceList) => getInvoiceList(filters),
  });
};

export const useDownloadInvoiceList = (
  options?: UseMutationOptions<string, ApiError, number>
) => {
  return useMutation(
    {
      mutationKey: downloadInvoice(),
      mutationFn: async (invoice: number) => {
        const response = await baseApi.get(`/invoices/${invoice}/download/`);
        return response.data;
      },
      ...options,
    },
    queryClient
  );
};
