/* eslint-disable import/no-duplicates */
import add from 'date-fns/add';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';

/**
 *
 * @param date Date to be formatted
 * @param dateFormat Format to be used
 * @returns A string with the date formatted using the provided format
 */
export const formatDate = (
  date?: number | string | Date,
  dateFormat = `dd/MM/yyyy`
): string => {
  if (!date) {
    return ``;
  }

  return format(add(new Date(date), {}), dateFormat, { locale: ptBR });
};

export const formatISOToDateTime = (isoString: string): string => {
  const date = new Date(isoString);

  // Verifica se a data é válida
  if (isNaN(date.getTime())) {
    return '';
  }

  const twoDigits = (num: number) => num.toString().padStart(2, '0');

  const day = twoDigits(date.getDate());
  const month = twoDigits(date.getMonth() + 1);
  const year = date.getFullYear();

  const hours = twoDigits(date.getHours());
  const minutes = twoDigits(date.getMinutes());
  const seconds = twoDigits(date.getSeconds());

  return `${day}/${month}/${year} às ${hours}:${minutes}:${seconds}`;
};
