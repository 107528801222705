/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';

import { Button } from '@wisecare-tech/design-system-web';

// import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { Select } from '@components/Select';

import {
  useDownloadInvoiceList,
  useFetchInvoiceList,
  useInvoiceByDay,
  useInvoiceByDetails,
  useInvoiceByOrgUnit,
  useInvoiceByService,
} from '@services/invoice';
import { InvoiceListRecords } from '@services/invoice/types';
import { useOrgList } from '@services/org';
import { useFetchOrgUnitsList } from '@services/orgUnit';

import {
  ActionsContainer,
  Container,
  Content,
  FiltersContainer,
  InfoContainer,
  InfoPeriod,
  InfoTitle,
  InfoValidity,
  InfoValue,
} from './styles';

interface AvailableOptions {
  years: string[];
  getMonthsForYear: (year: string) => { month: string; name: string }[];
}

interface BillingCardProps {
  visualization: number;
  setVisualization: (value: number) => any;
  getDataTable: (data: any) => any;
}

interface PaymentDetailsProps {
  id: number;
  totalAmount: number;
  dueDate: string;
  validityPeriod: string;
}

export const BillingCard: React.FC<BillingCardProps> = ({
  setVisualization,
  visualization,
  getDataTable,
}) => {
  const [org, setOrg] = useState<string>('all');
  const [orgUnit, setOrgUnit] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [totalAmount, setTotalAmount] = useState<PaymentDetailsProps>(
    {} as PaymentDetailsProps
  );
  const [options, setOptions] = useState<any>();
  const [availableMonths, setAvailableMonths] = useState<
    { month: string; name: string }[]
  >([]);

  // TODO: Adicionar options
  const { data: orgList } = useOrgList();
  const fetchSecondOptions = useFetchOrgUnitsList();
  const fetchInvoiceList = useFetchInvoiceList();
  const fetchInvoiceByOrgUnit = useInvoiceByOrgUnit();
  const fetchInvoiceByService = useInvoiceByService();
  const fetchInvoiceByDay = useInvoiceByDay();
  const fetchInvoiceByDetails = useInvoiceByDetails();

  const { mutate: downloadInvoice, error } = useDownloadInvoiceList({
    onSuccess: (data) => {
      console.log('Downloaded Invoice:', data);
    },
    onError: (error) => {
      console.error('Error downloading invoice:', error);
    },
  });

  // Trigger the mutation

  const months_translation: Record<string, string> = {
    January: 'Janeiro',
    February: 'Fevereiro',
    March: 'Março',
    April: 'Abril',
    May: 'Maio',
    June: 'Junho',
    July: 'Julho',
    August: 'Agosto',
    September: 'Setembro',
    October: 'Outubro',
    November: 'Novembro',
    December: 'Dezembro',
  };

  const handleOrgChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOrg(e.target.value);
    setOrgUnit('');

    if (e.target.value === '-1') {
      setAvailableMonths([]);
      setOptions(undefined);

      return;
    }

    fetchInvoiceList.mutate({
      filters: {
        orgId: Number(e.target.value),
      },
    });

    fetchSecondOptions.mutate({ filters: { orgId: Number(e.target.value) } });
  };

  const extractAvailableOptions = (
    data: InvoiceListRecords[]
  ): AvailableOptions => {
    // Extract unique years
    const years = Array.from(
      new Set(data.map((item) => item.begin.split('-')[0]))
    );

    // Function to get months based on the selected year
    const getMonthsForYear = (year: string) => {
      return data
        .filter((item) => item.begin.startsWith(year))
        .map((item) => {
          const [yearStr, monthStr] = item.begin.split('-');
          const yearNum = parseInt(yearStr, 10);
          const monthNum = parseInt(monthStr, 10);

          // Create a Date object using the original month (no decrement)
          const date = new Date(yearNum, monthNum - 1, 1);
          const name = date.toLocaleString('default', { month: 'long' });

          return { month: monthNum.toString().padStart(2, '0'), name };
        })
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.month === value.month)
        );
    };

    return { years, getMonthsForYear };
  };

  const formatDate = (dateString: string | undefined) => {
    const date = new Date(dateString ?? new Date());
    return date.toLocaleDateString('pt-BR');
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const year = event.target.value;
    setSelectedYear(year);
    setAvailableMonths(options?.getMonthsForYear(year));
  };

  const validData = useMemo(() => {
    if (fetchInvoiceByOrgUnit?.data?.orgUnits && visualization === 1) {
      return fetchInvoiceByOrgUnit.data.orgUnits;
    }
    if (fetchInvoiceByService?.data?.services && visualization === 2) {
      return fetchInvoiceByService.data.services;
    }
    if (fetchInvoiceByDay?.data?.days && visualization === 3) {
      return fetchInvoiceByDay.data.days;
    }
    if (fetchInvoiceByDetails?.data?.orgs && visualization === 4) {
      return fetchInvoiceByDetails.data.orgs;
    }
    return null;
  }, [
    fetchInvoiceByOrgUnit.data,
    fetchInvoiceByService.data,
    fetchInvoiceByDay.data,
    fetchInvoiceByDetails.data,
    visualization,
  ]);

  useEffect(() => {
    if (validData) {
      getDataTable(validData);
    }
  }, [validData, getDataTable]);

  useEffect(() => {
    if (fetchInvoiceList?.data) {
      const data = extractAvailableOptions(fetchInvoiceList?.data?.records);

      setOptions(data);
    }
  }, [fetchInvoiceList?.data, org]);

  useEffect(() => {
    if (org && selectedYear && selectedMonth && visualization) {
      const findOrg = orgList?.records.find((item) => item.id === Number(org));
      const mutationParams = {
        org: findOrg?.shortname,
        orgUnit: orgUnit || undefined,
        begin: `${selectedYear}-${selectedMonth}-01`,
        end: `${selectedYear}-${selectedMonth}-31`,
      };

      const mutations: Record<number, any> = {
        1: fetchInvoiceByOrgUnit,
        2: fetchInvoiceByService,
        3: fetchInvoiceByDay,
        4: fetchInvoiceByDetails,
      };

      const selectedMutation = mutations[visualization];
      if (selectedMutation) {
        const invoiceTotal = fetchInvoiceList?.data?.records?.find(
          (item) => item.begin === `${selectedYear}-${selectedMonth}-01`
        );

        const formattedBegin = invoiceTotal?.begin.replace(/-/g, '/');
        const formattedEnd = invoiceTotal?.end.replace(/-/g, '/');

        if (invoiceTotal && formattedBegin && formattedEnd) {
          setTotalAmount({
            id: invoiceTotal?.id,
            totalAmount: invoiceTotal?.value,
            dueDate: formattedEnd ?? '-',
            validityPeriod: `${formattedBegin ?? '-'} a ${formattedEnd ?? '-'}`,
          });
        }

        selectedMutation.mutate(mutationParams);
      }
    }
  }, [org, orgUnit, selectedMonth, selectedYear, visualization, orgList]);

  return (
    <Container>
      <FiltersContainer>
        <div style={{ display: 'flex', width: '100%', gap: 16 }}>
          <Select
            name="unit"
            label="Organização*"
            onChange={handleOrgChange}
            defaultValue={orgList?.records?.[0]?.fullname}
          >
            <option value={-1}>Selecione</option>
            {orgList?.records?.map((org) => (
              <option key={org?.id} value={org?.id}>
                {org?.fullname}
              </option>
            ))}
          </Select>
          <Select
            name="unit"
            label="Setor"
            onChange={(e) => setOrgUnit(e.target.value)}
          >
            <option value={-1}>Selecione</option>
            {fetchSecondOptions?.data?.records.map((unit) => (
              <option key={unit?.orgUnit?.id} value={unit?.orgUnit?.shortname}>
                {unit?.orgUnit?.fullname}
              </option>
            ))}
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            gap: 16,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              width: '100%',
              gap: 8,
            }}
          >
            <Select
              name="unit"
              label="Período*"
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <option value="all">Mês</option>
              {availableMonths?.map(({ month, name }) => (
                <option key={month} value={month}>
                  {months_translation[name]}
                </option>
              ))}
            </Select>
            <Select name="unit" onChange={handleYearChange}>
              <option>Ano</option>
              {options?.years?.map((year: string) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Select>
          </div>
          <Select
            name="unit"
            label="Visualização:"
            onChange={(e) => setVisualization(Number(e.target.value))}
          >
            <option key={1} value={1}>
              Consumo por setor
            </option>
            <option key={2} value={2}>
              Consumo por serviço
            </option>
            <option key={3} value={3}>
              Consumo por dia do mês
            </option>
            <option key={4} value={4}>
              Extrato de consumo detalhado
            </option>
          </Select>
        </div>
      </FiltersContainer>
      <Content>
        <InfoContainer>
          <InfoTitle>Valor total</InfoTitle>
          <InfoValue>R$ {totalAmount.totalAmount ?? '-'}</InfoValue>
          <InfoPeriod>
            Vencimento: <b>{formatDate(totalAmount.dueDate) ?? '-'}</b>
          </InfoPeriod>
          <InfoValidity>
            Vigência: <b>{totalAmount?.validityPeriod}</b>
          </InfoValidity>
        </InfoContainer>
        <ActionsContainer>
          <Button
            onClick={() => downloadInvoice(totalAmount.id)}
            disabled={!totalAmount.id}
          >
            <Icon name="download" iconStyle="rounded" />
            Baixar fatura
          </Button>
          {/* <Button variant="secondary">
            <Icon name="print" iconStyle="rounded" />
            Imprimir
          </Button> */}
        </ActionsContainer>
      </Content>
    </Container>
  );
};
