/* eslint-disable import/order */
// import { Header } from "@components/Header";
import { Header, Icon } from '@wisecare-tech/design-system-web';

import { SubHeader } from '@components/SubHeader';

import { BaseLayout } from '@layouts/types';

import { useLogout } from '@utils/cookies';

import { Body, Container } from './styles';
import { useCurrentUser } from '@stores/auth';

export const MainLayout: React.FC<BaseLayout> = ({ children }) => {
  const logout = useLogout();
  const { current } = useCurrentUser();

  return (
    <Container>
      <div style={{ width: '100%' }}>
        <Header
          onLogout={logout}
          options={[
            { title: 'Faturamento', href: '/' },
            { title: 'Billing', href: '/billing' },
          ]}
          user={{ name: current?.user.email ?? '', role: current?.user.login }}
          userOptions={[
            // {
            //   title: 'Perfil',
            //   href: '/perfil',
            //   icon: <Icon name="today" size="medium" color="blue-7" />,
            //   description: 'Visualize e edite seu perfil',
            // },
            {
              title: 'Sair',
              href: '/',
              icon: <Icon name="logout" color="red-7" />,
              description: 'Desconecte-se da sua conta',
            },
          ]}
        />
        <SubHeader />
      </div>
      <Body>{children}</Body>
    </Container>
  );
};
