/* eslint-disable @typescript-eslint/no-unused-vars */
import { ColumnDef } from '@tanstack/react-table';
import { SortButton, Typography } from '@wisecare-tech/design-system-web';

import { InvoiceByDayResponse } from '@services/invoice/types';

import { Information } from './styles';

export const columnsByDay: ColumnDef<InvoiceByDayResponse['days'][0]>[] = [
  {
    accessorKey: 'day',
    header: ({ column }) => <SortButton column={column}>Data</SortButton>,
    cell: ({ row }) => {
      const value = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">{value.date}</Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'total',
    header: ({ column }) => {
      return <SortButton column={column}>Consumo</SortButton>;
    },
    cell: ({ row }) => {
      const value = row.original;

      return <Typography variant="b4_14regular">{value.total}</Typography>;
    },
  },
  // {
  //   accessorKey: 'total',
  //   header: ({ column }) => <SortButton column={column}>Valor</SortButton>,
  //   cell: ({ row }) => {
  //     const total = row.getValue('total') as InvoiceByDayResponse['days'][0];

  //     // const rowOriginal = row.original;

  //     return (
  //       <Information>
  //         <Typography variant="b4_14regular">{total}</Typography>
  //       </Information>
  //     );
  //   },
  // },
];
