/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
import { MainLayout } from '@layouts/main';

import { BillingCard } from '@billing/components/Card';

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  PaginationComponent,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '@wisecare-tech/design-system-web';

import { columnsByDay } from '@billing/components/Table/columnsByDay';
import { columnsBySector } from '@billing/components/Table/columnsBySector';
import { columnsByService } from '@billing/components/Table/columnsByService';
import { columnsGeral } from '@billing/components/Table/columnsGeral';
import { EmptyTable } from '@billing/components/Table/emptyState';
import { useMemo, useState } from 'react';

export const Billing: React.FC = () => {
  const [visualization, setVisualization] = useState<number>(1);
  const [mutationTable, setMutationTable] = useState<any>([]);

  const tableColumns = useMemo(() => {
    const columns: Record<number, any> = {
      1: columnsBySector,
      2: columnsByService,
      3: columnsByDay,
      4: columnsGeral,
    };

    return columns[visualization];
  }, [visualization]);

  const table = useReactTable({
    data: mutationTable,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <MainLayout>
      <BillingCard
        getDataTable={setMutationTable}
        visualization={visualization}
        setVisualization={setVisualization}
      />
      <div>
        <Table>
          <TableHeader>
            {table?.getHeaderGroups()?.map((headerGroup) => (
              <>
                {headerGroup?.headers?.map((header) => (
                  <TableHead key={header?.id}>
                    {header?.isPlaceholder
                      ? null
                      : flexRender(
                          header?.column.columnDef.header,
                          header?.getContext()
                        )}
                  </TableHead>
                ))}
              </>
            ))}
          </TableHeader>
          <TableBody style={{ backgroundColor: 'white' }}>
            {table?.getRowModel().rows?.length ? (
              table?.getRowModel()?.rows.map((row) => (
                <TableRow key={row.id}>
                  {row?.getAllCells()?.map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={tableColumns?.length}
                  className="h-24 text-center"
                >
                  <EmptyTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {mutationTable?.length > 0 && (
            <TableFooter>
              <TableRow className="hover:tw-bg-white-1">
                <TableCell colSpan={tableColumns?.length}>
                  <PaginationComponent
                    totalItems={mutationTable?.length}
                    currentPage={1}
                    itemsPerPage={10}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </div>
    </MainLayout>
  );
};
