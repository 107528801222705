import { useMemo } from "react";

import { Bar, Legend, BarChart as RCBarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

import { useHistoricalInformation } from "@services/dashboard";



export const BarChart: React.FC = () => {

  const { data } = useHistoricalInformation();

  const hasHistoricalInformation = useMemo(() => {
    return data && data?.length > 0;
  }, [data]);

  const emptyStyle = hasHistoricalInformation ? {} : {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <ResponsiveContainer width='100%' height={200} style={emptyStyle}>
      {!hasHistoricalInformation ?
        <small>Não há informações históricas disponíveis</small> : (
        <RCBarChart data={data}>
          <XAxis dataKey="time" fontSize={12} />
          <Tooltip />
          <Legend iconType="circle" align="left" />
          <Bar
            name="Reuniões não gravadas"
            dataKey="notRecordedConferenceQuantity"
            fill="#1B4A7A"
          />
          <Bar
            name="Reuniões gravadas"
            dataKey="recordedConferenceQuantity"
            fill="#4B9EF4"
          />
        </RCBarChart>
      )
      }
    </ResponsiveContainer>
  )

}